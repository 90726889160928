import type * as apiTypes from './concepts.apiV2.types';

import * as uiLib from '@compliance.ai/web-components';

export const normalizeConceptsResponse = (
  response: apiTypes.FetchConceptsResponse
): apiTypes.NormalizedConceptsResponse => {
  return {
    count: response.count,
    labels: response.items.map(normalizeConcept)
  };
};

export const normalizeConcept = (
  concept: apiTypes.ConceptFromResponse
): apiTypes.NormalizedConcept => {
  return {
    name: concept.name,
    id: concept.id,
    description: concept.description,
    advancedSearch: concept.advanced_search,
    metaConcept: concept.meta_concept,
    isActive: concept.is_active,
    isRequirementType: concept.is_requirement_type,
    createdAt: uiLib.isValidDate(concept.created_at) ? concept.created_at : '',
    updatedAt: uiLib.isValidDate(concept.created_at) ? concept.created_at : ''
  };
};

import type { ConceptsTableConfig } from './useConceptsTableConfig.types';

import * as uiLib from '@compliance.ai/web-components';
import * as conceptsConstants from 'admin/constants/concepts';
import * as elements from '../elements';

export const useConceptsTableConfig = (): ConceptsTableConfig => {
  const columns: uiLib.Column[] = [
    {
      title: 'Name',
      dataKey: conceptsConstants.CONCEPTS_ATTRIBUTES.NAME,
      sortKey: conceptsConstants.CONCEPTS_ATTRIBUTES.NAME,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.LinkCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'ID',
      dataKey: conceptsConstants.CONCEPTS_ATTRIBUTES.ID,
      sortKey: conceptsConstants.CONCEPTS_ATTRIBUTES.ID,
      isDynamic: false,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.XS
    },
    {
      title: 'Meta-Concept',
      dataKey: conceptsConstants.CONCEPTS_ATTRIBUTES.META_CONCEPT,
      sortKey: conceptsConstants.CONCEPTS_ATTRIBUTES.META_CONCEPT,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'Status',
      dataKey: conceptsConstants.CONCEPTS_ATTRIBUTES.IS_ACTIVE,
      sortKey: conceptsConstants.CONCEPTS_ATTRIBUTES.IS_ACTIVE,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: elements.ConceptStatus,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'Updated',
      dataKey: conceptsConstants.CONCEPTS_ATTRIBUTES.UPDATED_AT,
      sortKey: conceptsConstants.CONCEPTS_ATTRIBUTES.UPDATED_AT,
      isDynamic: true,
      isInitiallyVisible: false,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'Description',
      dataKey: conceptsConstants.CONCEPTS_ATTRIBUTES.DESCRIPTION,
      sortKey: conceptsConstants.CONCEPTS_ATTRIBUTES.DESCRIPTION,
      isDynamic: true,
      isInitiallyVisible: false,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'Is Requirement Type',
      dataKey: conceptsConstants.CONCEPTS_ATTRIBUTES.IS_REQUIREMENT_TYPE,
      sortKey: conceptsConstants.CONCEPTS_ATTRIBUTES.IS_REQUIREMENT_TYPE,
      isDynamic: true,
      isInitiallyVisible: false,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    }
  ];

  return {
    columns
  };
};

import type { ConceptStatusOptions } from './useConceptStatusOptions.types';

import * as uiLib from '@compliance.ai/web-components';

import { useMemo } from 'react';

export const useConceptStatusOptions = (): ConceptStatusOptions => {
  return useMemo(
    () => ({
      options: [
        {
          value: true,
          label: (
            <uiLib.Typography className="concept-status__status__item active">
              <uiLib.IconSuccessRounded />
              Active
            </uiLib.Typography>
          )
        },
        {
          value: false,
          label: (
            <uiLib.Typography className="concept-status__status__item deactivated">
              <uiLib.IconWarningRounded />
              Deactivated
            </uiLib.Typography>
          )
        }
      ]
    }),
    []
  );
};

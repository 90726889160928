import type { RequirementTypeTableConfig } from './useRequirementTypeTableConfig.types';
import type { RequirementTypeProps } from '../RequirementType.types';

import * as constants from '../../../ConceptDetails.constants';
import * as elements from '../elements';
import * as uiLib from '@compliance.ai/web-components';

export const useRequirementTypeTableConfig = ({
  props,
  events
}: {
  props: RequirementTypeProps;
  events: {
    onDeleteDoc: RequirementTypeProps['onDeleteDoc'];
  };
}): RequirementTypeTableConfig => {
  const columns: uiLib.Column[] = [
    {
      title: 'ID',
      dataKey: constants.TABLE_FIELDS.ID,
      sortKey: constants.TABLE_FIELDS.ID,
      width: uiLib.TABLE_COLUMN_WIDTH.S,
      Component: uiLib.LinkCell
    },
    {
      title: 'Title',
      dataKey: constants.TABLE_FIELDS.TITLE,
      sortKey: constants.TABLE_FIELDS.TITLE
    },
    {
      title: 'Status',
      dataKey: constants.TABLE_FIELDS.PIPELINE_STATUS,
      sortKey: constants.TABLE_FIELDS.PIPELINE_STATUS,
      width: uiLib.TABLE_COLUMN_WIDTH.M,
      Component: elements.StatusCell
    },
    {
      title: 'Jurisdiction',
      dataKey: constants.TABLE_FIELDS.JURISDICTION,
      sortKey: constants.TABLE_FIELDS.JURISDICTION,
      width: uiLib.TABLE_COLUMN_WIDTH.M,
      Component: elements.JurisdictionCell
    },
    {
      title: 'Sentences',
      dataKey: constants.TABLE_FIELDS.SENTENCES,
      sortKey: constants.TABLE_FIELDS.SENTENCES,
      width: uiLib.TABLE_COLUMN_WIDTH.M,
      Component: elements.SentencesBoolCell
    }
  ];

  const actions: (uiLib.RowAction | uiLib.ColumnAction)[] = [
    {
      title: '',
      icon: <uiLib.IconDelete />,
      isRowAction: true,
      disableColumn: true,
      disabled: !props.isEditMode,
      trigger: events.onDeleteDoc
    } as uiLib.RowAction
  ];
  return {
    columns,
    actions
  };
};

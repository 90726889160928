import type { FC } from 'react';
import type { RequirementTypeProps } from './RequirementType.types';

import * as uiLib from '@compliance.ai/web-components';
import classNames from 'classnames';

import {
  useRequirementTypeData,
  useRequirementTypeStatusOptions,
  useRequirementTypeTableConfig,
  useRequirementTypeDataSelector
} from './hooks';

import './_requirement-type.scss';

export const RequirementType: FC<RequirementTypeProps> = props => {
  const { options } = useRequirementTypeStatusOptions();
  const { formattedData } = useRequirementTypeData({
    props,
    options
  });
  const tableConfig = useRequirementTypeTableConfig({
    props,
    events: {
      onDeleteDoc: props.onDeleteDoc
    }
  });
  const tableDataSelector = useRequirementTypeDataSelector({ props });
  return (
    <div className="requirement-type">
      <div className="requirement-type__header">
        <div className="requirement-type__header__tilte">
          <uiLib.Typography type={uiLib.TYPOGRAPHY_TYPES.H5}>Requirement Type</uiLib.Typography>
        </div>
        <div className="requirement-type__header__actions">
          <uiLib.LabeledField label="Status">
            <uiLib.Select
              className={classNames('requirement-type__header__status', {
                'requirement-type__header__status__active': props.isActive,
                'requirement-type__header__status__deactivated': !props.isActive
              })}
              type={uiLib.SELECT_TYPES.DEFAULT}
              value={formattedData.status}
              options={options}
              isClearable={false}
              isSearchable={false}
              isDisabled={!props.isEditMode}
              onChange={props.onChangeStatus}
            />
          </uiLib.LabeledField>
        </div>
      </div>
      <div className="requirement-type__body">
        <div className="requirement-type__body__search">
          <uiLib.TextField
            classNames={{
              labeledFieldContainer: 'requirement-type__body__search__field'
            }}
            label="Search documents ids"
            value={props.docIdsSearch}
            onChange={props.onChangeSearchDocs}
            isError={!!props.errorFields.documentSearch}
            errorMessage={props.errorFields.documentSearch}
            isDisabled={!props.isEditMode}
          />
          <uiLib.Button onClick={props.onAddDocuments} isDisabled={!props.isEditMode}>
            Add
          </uiLib.Button>
        </div>
        <div>
          {props.errorFields.documents && (
            <uiLib.Typography
              status={uiLib.TYPOGRAPHY_STATUS.ERROR}
              type={uiLib.TYPOGRAPHY_TYPES.BODY1}
            >
              {props.errorFields.documents}
            </uiLib.Typography>
          )}
          <uiLib.Table
            classNames={{
              container: 'requirement-type__body__table'
            }}
            rows={props.documents}
            onParamsChange={props.onSortDocuments}
            actions={tableConfig.actions}
            columns={tableConfig.columns}
            isLoading={props.isLoading}
            queryOptions={{
              queryKey: [props.documents.length],
              select: tableDataSelector
            }}
          />
        </div>
        <uiLib.LabeledField label="Jurisdictions">
          <uiLib.Select
            type={uiLib.SELECT_TYPES.DEFAULT}
            value={formattedData.jurisdictionOptions}
            options={formattedData.jurisdictionOptions}
            isClearable={true}
            isSearchable={false}
            isMulti
            isDisabled
          />
        </uiLib.LabeledField>
      </div>
    </div>
  );
};

import type { ConceptDetailsProps } from '../ConceptDetails.types';

import { COMPLEX_QUERY_KEY_GETTERS, QUERY_KEYS, QUERY_SECTIONS } from 'admin/constants/queryKeys';
import * as requirementTypesApi from 'shared/features/requirementTypes/requirementTypes.api';
import * as requirementTypeHelpers from 'shared/features/requirementTypes/requirementTypes.api.helpers';
import * as uiLib from '@compliance.ai/web-components';
import * as errorUtils from 'shared/utils/errors';

import { useQuery } from '@tanstack/react-query';

export const useConceptDetailsQueries = ({ props }: { props: ConceptDetailsProps }) => {
  const fetchRequirementType = useQuery({
    queryKey: COMPLEX_QUERY_KEY_GETTERS[QUERY_KEYS.REQUIREMENT_TYPES][
      QUERY_SECTIONS.REQUIREMENT_TYPES_EDIT
    ]({ id: Number(props.id) }),
    queryFn: async () => {
      if (uiLib.isNumber(Number(props.id))) {
        const response = await requirementTypesApi.fetchRequirementTypes({
          concept_id: Number(props.id)
        });
        return (
          requirementTypeHelpers.normalizeRequirementTypesResponse(response).requirementTypes || []
        );
      }
      return [];
    },
    onError: errorUtils.logReactQueryError
  });

  return {
    fetchRequirementType
  };
};

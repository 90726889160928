import type { MetaConceptDetailsData } from './useMetaConceptDetailsData.types';
import type { MetaConceptDetailsHandlers } from './useMetaConceptDetailsHandlers.types';
import type { FetchConceptsResponse } from 'shared/features/concepts/concepts.apiV2.types';

import ROUTES from 'admin/constants/routes';
import { NOTIFICATION_TYPES, setNotification } from 'admin/components/Notification';
import {
  CONTENT_MANAGEMENT_TAB_KEYS,
  CONTENT_MANAGEMENT_TAB_QUERY_PARAM
} from 'admin/containers/ContentManagement/ContentManagement.constants';
import * as helpers from '../MetaConceptDetails.helper';
import * as errorUtils from 'shared/utils/errors';
import * as conceptsApi from 'shared/features/concepts/concepts.apiV2';

import { useMetaConceptsApi } from 'shared/features/metaConcepts/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useHistory } from 'admin/utils/hooks';
import { ConceptFromResponse } from 'shared/features/concepts/concepts.apiV2.types';

export const useMetaConceptDetailsHandlers = ({
  localActions,
  localState,
  formattedData,
  reduxState
}: {
  localActions: MetaConceptDetailsData['localActions'];
  localState: MetaConceptDetailsData['localState'];
  formattedData: MetaConceptDetailsData['formattedData'];
  reduxState: MetaConceptDetailsData['reduxState'];
}): MetaConceptDetailsHandlers => {
  const history = useHistory();
  const location = useLocation();
  const navigate = useNavigate();
  const metaConceptsApi = useMetaConceptsApi();

  const handleCalculateMetaConcept: MetaConceptDetailsHandlers['handleCalculateMetaConcept'] =
    useCallback(() => {
      const formatMetaConceptDetails = helpers.fromRawInfoToDetails(reduxState.metaConcept);
      localActions.setMetaConceptDetails(formatMetaConceptDetails);
    }, [localActions, reduxState.metaConcept]);

  const handleToggleEditMode: MetaConceptDetailsHandlers['handleToggleEditMode'] = () => {
    if (localState.editMode) {
      handleCalculateMetaConcept();
    }
    localActions.setEditMode(!localState.editMode);
  };

  const handleChanges: MetaConceptDetailsHandlers['handleChanges'] = (name, value) => {
    return localActions.setMetaConceptDetails(metaConceptDetails => ({
      ...metaConceptDetails,
      [name]: value
    }));
  };

  const handleSaveChanges: MetaConceptDetailsHandlers['handleSaveChanges'] = async () => {
    const { metaConceptDetails: metaConceptInfo, errors } = helpers.fromDetailsToRawInfo(
      localState.metaConceptDetails,
      reduxState.metaConcept
    );
    if (errors.length) {
      localActions.setErrorFields(errors.map(err => err?.title));
      errors.forEach(error => setNotification({ message: error, type: NOTIFICATION_TYPES.ERROR }));
      return;
    }
    handleToggleEditMode();
    if (!isNaN(Number(formattedData.metaConceptId))) {
      try {
        const responseData = await metaConceptsApi.modifyMetaConcept(
          Number(formattedData.metaConceptId),
          {
            ...metaConceptInfo
          }
        );

        localActions.setMetaConceptDetails({
          ...localState.metaConceptDetails,
          ...responseData
        });
        if (responseData.id) {
          localActions.setErrorFields([]);
        }
      } catch (e) {
        // here we used to call errorUtils to do logError(e as Error);
        // that led to double notification along the error handling chain
      }
    } else {
      try {
        const responseData = await metaConceptsApi.addMetaConcept(metaConceptInfo);
        localActions.setMetaConceptDetails({
          ...localState.metaConceptDetails,
          ...responseData
        });
        const responseDataId = responseData?.id;
        if (responseDataId) {
          localActions.setErrorFields([]);
          navigate(`${ROUTES.metaConceptTool}/${responseDataId}`);
        }
      } catch (e) {
        // here we used to call errorUtils to do logError(e as Error);
        // that led to double notification along the error handling chain
      }
    }
  };

  const handleOnDelete: MetaConceptDetailsHandlers['handleOnDelete'] = async () => {
    await metaConceptsApi.deleteMetaConcept(Number(formattedData.metaConceptId));
    navigate(
      `${ROUTES.contentManagement}?${CONTENT_MANAGEMENT_TAB_QUERY_PARAM}=${CONTENT_MANAGEMENT_TAB_KEYS.META_CONCEPTS}`,
      {
        ...location.state,
        relative: 'path'
      }
    );
  };

  const handleToggleConfirmationModal: MetaConceptDetailsHandlers['handleToggleConfirmationModal'] =
    () => {
      localActions.setIsOpen(!localState.isOpen);
    };

  const handleStatusDropdownToggle: MetaConceptDetailsHandlers['handleStatusDropdownToggle'] =
    isOpen => () => {
      localActions.setIsOpen(isOpen);
    };

  const handleGoBack: MetaConceptDetailsHandlers['handleGoBack'] = () => {
    navigate(`${ROUTES.contentManagement}?tab=meta_concepts`, location.state);
  };

  const handleConceptsFetch: MetaConceptDetailsHandlers['handleConceptsFetch'] = async (
    inputString: string
  ) => {
    try {
      const response = await conceptsApi.fetchConceptsData({ multi_input: inputString });

      return response.items.map((concept: ConceptFromResponse) => ({
        ...concept,
        label: concept.name,
        value: concept.id
      }));
    } catch (e) {
      errorUtils.logError(e);
      return [];
    }
  };

  return {
    handleStatusDropdownToggle,
    handleGoBack,
    handleCalculateMetaConcept,
    handleToggleEditMode,
    handleChanges,
    handleSaveChanges,
    handleOnDelete,
    handleToggleConfirmationModal,
    handleConceptsFetch
  };
};

import type { FC } from 'react';
import type { ConceptStatusProps } from './ConceptStatus.types';
import type { ConceptFromResponse } from 'shared/features/concepts/concepts.apiV2.types';

import Badge from 'admin/components/Badge';
import { getConceptStatusBadgeInfo } from './ConceptStatus.helpers';

export const ConceptStatus: FC<ConceptStatusProps> = ({ row }) => {
  const conceptValue = row as ConceptFromResponse;
  const badgeInfo = getConceptStatusBadgeInfo(conceptValue?.is_active);
  return (
    <Badge type={badgeInfo.type} size="medium" title={badgeInfo.text}>
      {badgeInfo.text}
    </Badge>
  );
};

import type { DocsModalHandlers } from './useDocsModalHandlers.types';
import type { DocsModalData } from './useDocsModalData.types';
import type { DocsModalProps } from '../DocsModal.types';
import type { ReportDocumentFromResponse } from 'shared/features/crawlerSettings/reportDocuments/reportDocuments.api.types';

import * as errorUtils from 'shared/utils/errors';
import * as reportDocumentsApi from 'shared/features/crawlerSettings/reportDocuments/reportDocuments.api';
import { SORTING_PARAMS } from 'admin/constants/report_documents';
import * as uiLib from '@compliance.ai/web-components';
import { useCallback } from 'react';

export const useDocsModalHandlers = ({
  props,
  localActions,
  localState
}: {
  props: Pick<DocsModalProps, 'reportId'>;
  localActions: DocsModalData['localActions'];
  localState: DocsModalData['localState'];
}): DocsModalHandlers => {
  const handleDocumentsFetch: DocsModalHandlers['handleDocumentsFetch'] = useCallback(
    async params => {
      try {
        const response = await reportDocumentsApi.api_getReportDocuments(props.reportId, {
          ...params,
          ...localState.filters,
          [uiLib.TABLE_PARAMS.SORT]: {
            id: {
              sorting_priority: SORTING_PARAMS.SORTING_PRIORITY,
              sorting_direction: SORTING_PARAMS.SORTING_ORDER
            }
          }
        });

        return {
          results: response.items,
          count: response.count
        };
      } catch (e) {
        errorUtils.logError(e);

        return {
          results: [],
          count: 0
        };
      }
    },
    [localState.filters, props.reportId]
  );

  const handleBulkReprocessModalToggle: DocsModalHandlers['handleBulkReprocessModalToggle'] =
    isOpen => async () => {
      localActions.setIsBulkReprocessModalOpen(isOpen);
    };

  const handleTableRefresh: DocsModalHandlers['handleTableRefresh'] = () => {
    localActions.setReloadTrigger(reloadTrigger => !reloadTrigger);
  };

  const handleRowsSelection: DocsModalHandlers['handleRowsSelection'] = rowKeys => {
    localActions.setSelectedDocumentIds(rowKeys.flatMap(rowKey => Number(rowKey) || []));
  };

  const handleSelectAllClick: DocsModalHandlers['handleSelectAllClick'] = async params => {
    try {
      const response = await reportDocumentsApi.api_getReportDocuments(props.reportId, {
        ...params,
        [uiLib.TABLE_PARAMS.SORT]: {
          id: {
            sorting_priority: SORTING_PARAMS.SORTING_PRIORITY,
            sorting_direction: SORTING_PARAMS.SORTING_ORDER
          }
        },
        limit: 600
      });

      return response.items.flatMap((item: ReportDocumentFromResponse) => Number(item?.id) || []);
    } catch (e) {
      errorUtils.logError(e);
      return [];
    }
  };

  const handleFiltersSubmit: DocsModalHandlers['handleFiltersSubmit'] = filters => {
    localActions.setTableParams({
      ...localState.tableParams,
      [uiLib.TABLE_PARAMS.OFFSET]: 0
    });
    localActions.setFilters(filters);
    localActions.setReloadTrigger(reloadTrigger => !reloadTrigger);
    localActions.setDeselectAllDocumentsTrigger(
      deselectAllDocumentsTrigger => !deselectAllDocumentsTrigger
    );
  };

  return {
    handleDocumentsFetch,
    handleBulkReprocessModalToggle,
    handleTableRefresh,
    handleRowsSelection,
    handleSelectAllClick,
    handleFiltersSubmit
  };
};

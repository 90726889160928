import type { FC } from 'react';
import type { JurisdictionCellProps, JurisdictionCellValue } from './JurisdictionCell.types';

import * as uiLib from '@compliance.ai/web-components';

export const JurisdictionCell: FC<JurisdictionCellProps> = ({ cellValue }) => {
  const jurisdictionCell = cellValue as JurisdictionCellValue;
  return (
    <uiLib.Typography
      status={
        jurisdictionCell.isError ? uiLib.TYPOGRAPHY_STATUS.ERROR : uiLib.TYPOGRAPHY_STATUS.INITIAL
      }
    >
      {jurisdictionCell.jurisdiction}
    </uiLib.Typography>
  );
};

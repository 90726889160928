export enum CONCEPTS_ATTRIBUTES {
  ID = 'id',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  NAME = 'name',
  DESCRIPTION = 'description',
  IS_ACTIVE = 'is_active',
  META_CONCEPT = 'meta_concept',
  IS_REQUIREMENT_TYPE = 'is_requirement_type'
}

import {
  ConceptFromResponse,
  ModifyConceptPayload,
  NormalizedConcept
} from 'shared/features/concepts/concepts.apiV2.types';

export const getUpdatedFields = (
  newConcept: Partial<NormalizedConcept>,
  oldConcept: ConceptFromResponse
): Partial<ModifyConceptPayload[0]> => {
  const updates: Partial<ModifyConceptPayload[0]> = {};
  if (oldConcept.name !== newConcept.name && newConcept.name) {
    updates.name = newConcept.name;
  }
  if (oldConcept.description !== newConcept.description && newConcept.description) {
    updates.description = newConcept.description;
  }
  if (oldConcept.is_active !== newConcept.isActive) {
    updates.is_active = newConcept.isActive;
  }
  if (oldConcept.is_requirement_type !== newConcept.isRequirementType) {
    updates.is_requirement_type = newConcept.isRequirementType;
  }
  if (oldConcept.advanced_search !== newConcept.advancedSearch && newConcept.advancedSearch) {
    updates.advanced_search = newConcept.advancedSearch;
  }
  if (oldConcept?.meta_concept?.id !== newConcept?.metaConcept?.id && newConcept?.metaConcept?.id) {
    updates.meta_concept_id = newConcept?.metaConcept?.id;
  }

  return updates;
};

import type * as apiTypes from './metaConcepts.api.types';

import * as uiLib from '@compliance.ai/web-components';

export const normalizeMetaConceptsResponse = (
  response: apiTypes.GetMetaConceptsResponse
): apiTypes.NormalizedMetaConceptsResponse => {
  return {
    count: response.count,
    labels: response.items.map(normalizeMetaConcept)
  };
};

export const normalizeMetaConcept = (
  metaConcept: apiTypes.MetaConceptFromResponse
): apiTypes.NormalizedMetaConcept => {
  return {
    id: metaConcept.id,
    name: metaConcept.name,
    createdAt: uiLib.isValidDate(metaConcept.created_at) ? metaConcept.created_at : '',
    userId: metaConcept.user_id
  };
};

export const formatMetaConceptsOption = (
  normalizedMetaConcept: Pick<apiTypes.NormalizedMetaConcept, 'id' | 'name'>
): apiTypes.MetaConceptOption => ({
  label: normalizedMetaConcept.name,
  value: normalizedMetaConcept.id
});

export const formatMetaCategoriesOptions = (
  response: apiTypes.NormalizedMetaConceptsResponse
): apiTypes.MetaConceptOption[] => {
  return response.labels.map(formatMetaConceptsOption);
};

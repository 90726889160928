import type { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';

import {
  useConceptPageData,
  useConceptPageHandlers,
  useConceptPageLifecycle,
  useConceptPageTabs
} from './hooks';

import './_concept-page.scss';

export const ConceptPage: FC = () => {
  const { localState, localActions, formattedData, reduxState } = useConceptPageData();
  const handlers = useConceptPageHandlers({
    localActions,
    formattedData,
    reduxState
  });
  useConceptPageLifecycle({
    onGetConcept: handlers.handleGetConcept
  });
  const { tabs } = useConceptPageTabs({
    id: formattedData.id,
    isNewMode: formattedData.isNewMode,
    concept: reduxState.concept,
    onChangeConcept: handlers.handleUpdateConcept
  });
  return (
    <div>
      <uiLib.Tabs
        classNames={{
          tabsBar: 'concept-page__tabs-bar'
        }}
        ExtraEndContent={
          <elements.ConceptStatus
            concept={reduxState.concept}
            isDisables={formattedData.isNewMode}
            onChangeConcept={handlers.handleUpdateConcept}
          />
        }
        activeTabIndex={localState.activeTabIndex}
        onSelect={handlers.handleChangeTab}
        tabs={tabs}
      />
    </div>
  );
};

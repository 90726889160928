import type { MomentInput } from 'moment';
import type * as documentDataAPi from 'shared/features/documentData/documentData.apiV2';

import * as uiLib from '@compliance.ai/web-components';
import * as documentConstants from 'admin/constants/document';
import * as documentDataConstants from 'shared/features/documentData/documentData.constants';

export const getLocalDateEnd = (date?: MomentInput): Date => {
  const startOfNextDay = uiLib.getDateAfterDate({
    date,
    amount: 1,
    unitOfTime: uiLib.DATE_UNITS.DAY
  });

  return uiLib.getDateBeforeDate({
    date: startOfNextDay,
    amount: 1,
    unitOfTime: uiLib.DATE_UNITS.MILLISECONDS
  }) as Date;
};

export const getToday = () =>
  uiLib.getStartOfDate(new Date(), uiLib.DATE_UNITS.DAY, {
    passedTimezone: uiLib.getLocalTimezone(),
    shouldKeepLocalTime: false
  });

export const formatStartDate = (date?: MomentInput) => {
  return date
    ? uiLib.formatDate(date, {
        format: uiLib.DATE_FORMATS.API_DATE_TIME_WITH_T,
        passedTimezone: uiLib.getLocalTimezone(),
        timezone: uiLib.TIMEZONES.UTC
      })
    : null;
};

export const formatEndDate = (date?: MomentInput) => {
  if (date === null) {
    return null;
  }

  /**
   * getLocalDateEnd tries to solve issue with filtering documents by public_date,
   * if end date is before 12:00AM of the day document was published, api doesn't return it,
   * solution is to increase the end date up to 23:59:59 for all filtering requests
   */
  const endDate = getLocalDateEnd(date);

  return uiLib.getEndOfDate(endDate, uiLib.DATE_UNITS.DAY, {
    format: uiLib.DATE_FORMATS.API_DATE_TIME_WITH_T,
    passedTimezone: uiLib.getLocalTimezone(),
    timezone: uiLib.TIMEZONES.UTC
  });
};

export const formatTextWithModifiers =
  ({ queryParamKey }: { queryParamKey: string }) =>
  (filterValue: uiLib.FilterValue): Record<string, unknown> => {
    const value = filterValue as uiLib.TextFieldWithModifiersProps['value'];
    if (value?.value || value?.modifier) {
      return {
        [queryParamKey]: `${value?.modifier}_${value?.value}`
      };
    }

    return {};
  };

export const formatDateWithModifiers =
  ({
    startDateQueryParamKey,
    endDateQueryParamKey
  }: {
    startDateQueryParamKey: string;
    endDateQueryParamKey: string;
  }) =>
  (filterValue: uiLib.FilterValue): Record<string, unknown> => {
    const value = filterValue as uiLib.DatePickerWithModifiersProps['value'];

    switch (value?.modifier) {
      case uiLib.DATE_MODIFIERS.BETWEEN: {
        return {
          [startDateQueryParamKey]: formatStartDate(value?.dateA),
          [endDateQueryParamKey]: formatEndDate(value?.dateB)
        };
      }

      case uiLib.DATE_MODIFIERS.ON: {
        return {
          [startDateQueryParamKey]: formatStartDate(value?.dateA),
          [endDateQueryParamKey]: formatEndDate(value?.dateA)
        };
      }

      case uiLib.DATE_MODIFIERS.ON_OR_BEFORE: {
        return {
          [endDateQueryParamKey]: formatEndDate(value?.dateA)
        };
      }

      case uiLib.DATE_MODIFIERS.ON_OR_AFTER: {
        return {
          [startDateQueryParamKey]: formatStartDate(value?.dateA)
        };
      }

      case uiLib.DATE_MODIFIERS.TODAY: {
        const today = getToday();
        return {
          [startDateQueryParamKey]: formatStartDate(today),
          [endDateQueryParamKey]: formatEndDate(today)
        };
      }

      case uiLib.DATE_MODIFIERS.YESTERDAY: {
        const today = getToday();
        const date = uiLib.getDateBeforeDate({
          date: today,
          amount: 1,
          unitOfTime: uiLib.DATE_UNITS.DAY
        });

        return {
          [startDateQueryParamKey]: formatStartDate(date instanceof Date ? date : null),
          [endDateQueryParamKey]: formatEndDate(date instanceof Date ? date : null)
        };
      }

      case uiLib.DATE_MODIFIERS.TOMORROW: {
        const today = getToday();
        const date = uiLib.getDateAfterDate({
          date: today,
          amount: 1,
          unitOfTime: uiLib.DATE_UNITS.DAY
        });

        return {
          [startDateQueryParamKey]: formatStartDate(date instanceof Date ? date : null),
          [endDateQueryParamKey]: formatEndDate(date instanceof Date ? date : null)
        };
      }

      default: {
        return {};
      }
    }
  };

export const formatText =
  ({ queryParamKey }: { queryParamKey: string }) =>
  (filterValue: uiLib.FilterValue) => {
    const value = filterValue as string;

    if (value) {
      return {
        [queryParamKey]: value
      };
    }

    return {};
  };

export const formatMultiSelectValues =
  ({ queryParamKey }: { queryParamKey: string }) =>
  (filterValue: uiLib.FilterValue) => {
    const values = filterValue as uiLib.SelectOption[];

    if (Array.isArray(values) && values.length) {
      return {
        [queryParamKey]: values.map(({ value }) => value)
      };
    }

    return {};
  };

export const formatSelectValues =
  ({ queryParamKey }: { queryParamKey: string }) =>
  (filterValue: uiLib.FilterValue) => {
    const option = filterValue as uiLib.SelectOption;
    if (option) {
      return {
        [queryParamKey]: option?.value
      };
    }

    return {};
  };

export const formatNumericWithModifiersValue =
  ({
    countQueryParamKey,
    countFromQueryParamKey,
    countToQueryParamKey
  }: {
    countQueryParamKey: string;
    countFromQueryParamKey: string;
    countToQueryParamKey: string;
  }) =>
  (filterValue: uiLib.FilterValue) => {
    const value = filterValue as uiLib.NumericFieldWithModifiersProps['value'];

    switch (value?.modifier) {
      case uiLib.NUMERIC_MODIFIERS.EQUAL: {
        return {
          [countQueryParamKey]: value.valueA
        };
      }

      case uiLib.NUMERIC_MODIFIERS.BETWEEN: {
        return {
          [countFromQueryParamKey]: value.valueA,
          [countToQueryParamKey]: value.valueB
        };
      }

      case uiLib.NUMERIC_MODIFIERS.GREATER_OR_EQUAL: {
        return {
          [countFromQueryParamKey]: value.valueA
        };
      }

      case uiLib.NUMERIC_MODIFIERS.LESS_OR_EQUAL: {
        return {
          [countToQueryParamKey]: value.valueA
        };
      }

      default: {
        return {};
      }
    }
  };

export const DOCUMENT_FILTER_VALUES_PARSERS: Record<
  string,
  (value: uiLib.FilterValue) => Parameters<typeof documentDataAPi.fetchDocumentsData>[0]
> = {
  [documentConstants.DOCUMENT_ATTRIBUTES.PIPELINE_STATUS]: formatMultiSelectValues({
    queryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.PIPELINE_STATUS
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.PIPELINE_STATUSES]: formatMultiSelectValues({
    queryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.PIPELINE_STATUSES
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.REPORT_STATUSES]: formatMultiSelectValues({
    queryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.REPORT_STATUSES
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.INTERACTION_STATUS]: formatMultiSelectValues({
    queryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.INTERACTION_STATUS
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.INTERACTION_STATUSES]: formatMultiSelectValues({
    queryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.INTERACTION_STATUSES
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.JURISDICTION]: formatMultiSelectValues({
    queryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.JURISDICTION
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.AGENCY_ID]: formatMultiSelectValues({
    queryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.AGENCY_ID
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.DOC_SCRAPPED_CATEGORY]: formatMultiSelectValues({
    queryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.DOC_SCRAPPED_CATEGORY
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.CAI_CATEGORY_IDS]: formatMultiSelectValues({
    queryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.CAI_CATEGORY_IDS
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.SPIDER_NAME]: formatMultiSelectValues({
    queryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.SPIDER_NAME
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.SPIDER_NAMES]: formatMultiSelectValues({
    queryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.SPIDER_NAMES
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.OFFICIAL_ID]: formatTextWithModifiers({
    queryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.OFFICIAL_ID
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.DOCKET_ID]: formatTextWithModifiers({
    queryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.DOCKET_ID
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.OFFICIAL_IDS]: formatTextWithModifiers({
    queryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.OFFICIAL_IDS
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.CITATION]: formatTextWithModifiers({
    queryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.CITATION
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.PUBLICATION_DATE]: formatDateWithModifiers({
    startDateQueryParamKey:
      documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.PUBLICATION_START_DATE,
    endDateQueryParamKey:
      documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.PUBLICATION_END_DATE
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.CREATED_AT]: formatDateWithModifiers({
    startDateQueryParamKey:
      documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.CREATED_AT_START_DATE,
    endDateQueryParamKey:
      documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.CREATED_AT_END_DATE
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.UPDATED_AT]: formatDateWithModifiers({
    startDateQueryParamKey:
      documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.UPDATED_AT_START_DATE,
    endDateQueryParamKey:
      documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.UPDATED_AT_END_DATE
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.EFFECTIVE_DATE]: formatDateWithModifiers({
    startDateQueryParamKey:
      documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.EFFECTIVE_ON_START_DATE,
    endDateQueryParamKey:
      documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.EFFECTIVE_ON_END_DATE
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.COMMENT_CLOSED_DATE]: formatDateWithModifiers({
    startDateQueryParamKey:
      documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.COMMENT_CLOSED_ON_START_DATE,
    endDateQueryParamKey:
      documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.COMMENT_CLOSED_ON_END_DATE
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.RESPONDENT_NAME]: formatTextWithModifiers({
    queryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.RESPONDENT_NAME
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.VIOLATED_RULES]: formatTextWithModifiers({
    queryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.VIOLATED_RULES
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.VIOLATION]: formatTextWithModifiers({
    queryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.VIOLATION
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.TITLE]: formatTextWithModifiers({
    queryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.TITLE
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.PDF_URL]: formatTextWithModifiers({
    queryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.PDF_URL
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.WEB_URL]: formatTextWithModifiers({
    queryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.WEB_URL
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.EITL_LABELS]: formatMultiSelectValues({
    queryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.EITL_LABELS
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.VERSIONS]: formatNumericWithModifiersValue({
    countQueryParamKey:
      documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.DOCUMENT_VERSIONS_COUNT,
    countFromQueryParamKey:
      documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.DOCUMENT_VERSIONS_COUNT_FROM,
    countToQueryParamKey:
      documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.DOCUMENT_VERSIONS_COUNT_TO
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.MONETARY_PENALTY]: formatNumericWithModifiersValue({
    countQueryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.MONETARY_PENALTY,
    countFromQueryParamKey:
      documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.MONETARY_PENALTY_FROM,
    countToQueryParamKey:
      documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.MONETARY_PENALTY_TO
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.HAS_UNOFFICIAL_PUBLICATION_DATE]: formatSelectValues({
    queryParamKey:
      documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.HAS_UNOFFICIAL_PUBLICATION_DATE
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.DIFFABLE]: formatSelectValues({
    queryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.DIFFABLE
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.VERSIONIZED]: formatSelectValues({
    queryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.VERSIONIZED
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.ONLY_LAST_VERSION]: formatSelectValues({
    queryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.ONLY_LAST_VERSION
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.PARENT_DOCUMENT]: formatSelectValues({
    queryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.PARENT_DOCUMENT
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.IS_SHELF_EXIST]: formatSelectValues({
    queryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.IS_SHELF_EXIST
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.IS_SHELF_EXIST]: formatSelectValues({
    queryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.IS_SHELF_EXIST
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.SHELF_ID]: formatText({
    queryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.SHELF_ID
  }),
  [documentConstants.DOCUMENT_ATTRIBUTES.ROOT_SHELF_ID]: formatText({
    queryParamKey: documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.ROOT_SHELF_ID
  })
};

export const formatDocumentsQueryParams = (
  values: uiLib.FiltersProps['values'],
  searchValue: string
): Record<string, unknown> => {
  return Object.entries(values).reduce(
    (formattedQueryParams, [filterKey, filterValue]) => {
      return {
        ...formattedQueryParams,
        ...DOCUMENT_FILTER_VALUES_PARSERS[filterKey](filterValue)
      };
    },
    {
      [documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.DOC_IDS]: searchValue
        ? searchValue.split(', ')
        : []
    } as Record<string, unknown>
  );
};

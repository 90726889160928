import type { GlobalState } from '../../reducers';
import type { ConceptFromResponse } from './concepts.apiV2.types';

import { createSelector } from 'reselect';

export const getCurrentConceptsReducer = createSelector(
  (state: GlobalState) => state,
  (state: GlobalState) => state.concepts
);

export const getConcepts = createSelector(
  getCurrentConceptsReducer,
  (currentConceptsReducer): ConceptFromResponse[] => {
    return currentConceptsReducer.items;
  }
);

export const getFirstConcept = createSelector(
  getConcepts,
  (currentConceptsReducer): ConceptFromResponse => {
    return currentConceptsReducer[0] ?? {};
  }
);

import type { ComponentProps } from 'react';
import type { RequirementTypeProps } from '../RequirementType.types';
import type { DocumentDataFromResponse } from 'shared/features/documentData/documentData.types';
import type * as elements from '../elements';

import * as constants from '../../../ConceptDetails.constants';
import * as documentConstants from 'admin/constants/docPipelineStatuses';
import * as uiLib from '@compliance.ai/web-components';
import ROUTES from 'admin/constants/routes';

export const useRequirementTypeDataSelector = ({ props }: { props: RequirementTypeProps }) => {
  return (
    data: Awaited<ReturnType<Required<ComponentProps<typeof uiLib.Table>>['onDataFetch']>>
  ): Awaited<ReturnType<Required<ComponentProps<typeof uiLib.Table>>['onDataFetch']>> => {
    return {
      results: data.results.map((result, index) => {
        const document = result as DocumentDataFromResponse;
        return {
          [constants.TABLE_FIELDS.DOC_ID]: document.id,
          [constants.TABLE_FIELDS.ID]: {
            href: `${window.location.origin}${ROUTES.documents}/${document.id}`,
            tooltip: document.id,
            children: document.id
          } as uiLib.LinkCellValue,
          [constants.TABLE_FIELDS.TITLE]: document.title,
          [constants.TABLE_FIELDS.SENTENCES]: document.diffable,
          [constants.TABLE_FIELDS.PIPELINE_STATUS]: {
            pipeline_status: document.pipeline_status,
            isError:
              props.errorFields.documents &&
              document.pipeline_status !== documentConstants.DOCUMENT_PIPELINE_STATUS.PROCESSED
          } as elements.StatusCellValue,
          [constants.TABLE_FIELDS.JURISDICTION]: {
            jurisdiction: document.jurisdiction ?? '-/-',
            isError: props.errorFields.documents && !uiLib.isNumber(document.jurisdiction_id)
          } as elements.JurisdictionCellValue
        };
      }),
      count: data.count
    };
  };
};

import type * as eitlLabelsApiTypes from 'shared/features/eitlLabels/eitlLabels.api.types';
import type * as caiCategoriesApiTypes from 'shared/features/categories/caiCategories/caiCategories.apiV2.types';
import type * as scrapedCategoriesApiTypes from 'shared/features/categories/scrapedCategories/scrapedCategories.apiV2.types';
import type * as requirementTypesApiTypes from 'shared/features/requirementTypes/requirementTypes.api.types';
import type * as documentDataTypes from 'shared/features/documentData/documentData.types';

/**
 *
 * Maintaining query key constants is crucial. Without a properly defined constants we'll end up with a
 * bunch of random requests that will be impossible to refactor
 *
 * Each QUERY_KEY represents some entity
 *
 */
export enum QUERY_KEYS {
  SESSION_TOKENS = 'sessionTokens',
  CURRENT_USER = 'currentUser',
  DOCUMENTS_DATA = 'documentsData',
  DOCUMENTS_DATA_COUNT = 'documentsDataCount',
  DOCUMENT_FILE_LINK = 'documentFileLink',
  EITL_LABELS = 'eitlLabels',
  REPORT_DOCUMENTS = 'reportDocuments',
  DOCUMENT_EVENTS = 'documentEvents',
  DOCUMENT_AUDIT_ENTRIES = 'documentAuditEntries',
  SPIDER_RUN_REPORTS = 'spiderRunReports',
  EXTERNAL_API_CRAWLS = 'externalApiCrawls',
  COMMANDS = 'commands',
  COMMAND_REQUESTS = 'commandRequests',
  CAI_CATEGORIES = 'cai_categories',
  SCRAPED_CATEGORIES = 'scraped_categories',
  NEWS_PREMIUM_SOURCE = 'news_premium_source',
  REQUIREMENT_TYPES = 'requirement_types'
}

export enum QUERY_SECTIONS {
  INPUT_GET_EITL_LABELS = 'inputGetEitlLabels',
  CONTENT_MANAGEMENT_EITL_LABELS_ADD = 'contentManagementEitlLabelsAdd',
  CONTENT_MANAGEMENT_EITL_LABELS_RENAME = 'contentManagementEitlLabelsRename',
  CONTENT_MANAGEMENT_EITL_LABELS_DELETE = 'contentManagementEitlLabelsDelete',
  CONTENT_MANAGEMENT_EITL_LABELS_IMPORT = 'contentManagementEitlLabelsImport',
  DOCUMENTS_BULK_EDIT = 'documentsBulkEdit',
  DOCUMENT_BULK_COMMANDS = 'documentBulkCommands',
  CONTENT_MANAGEMENT_CAI_CATEGORIES_EDIT = 'contentManagementCaiCategoriesEdit',
  CONTENT_MANAGEMENT_CAI_CATEGORIES_ADD = 'contentManagementCaiCategoriesAdd',
  CONTENT_MANAGEMENT_SCRAPED_CATEGORIES_EDIT = 'contentManagementScrapedCategoriesEdit',
  REQUIREMENT_TYPES_EDIT = 'requirementTypesEdit'
}

export const COMPLEX_QUERY_KEY_GETTERS = {
  [QUERY_KEYS.DOCUMENTS_DATA]: {
    [QUERY_SECTIONS.DOCUMENTS_BULK_EDIT]: ({
      selectedDocumentIds
    }: {
      selectedDocumentIds: documentDataTypes.DocumentDataFromResponse['id'][];
    }) => {
      return [QUERY_KEYS.DOCUMENTS_DATA, QUERY_SECTIONS.DOCUMENTS_BULK_EDIT, selectedDocumentIds];
    },
    [QUERY_SECTIONS.DOCUMENT_BULK_COMMANDS]: () => {
      return [QUERY_KEYS.COMMANDS, QUERY_SECTIONS.DOCUMENT_BULK_COMMANDS];
    }
  },
  [QUERY_KEYS.EITL_LABELS]: {
    [QUERY_SECTIONS.INPUT_GET_EITL_LABELS]: (...args: eitlLabelsApiTypes.GetEitlLabelsPayload) => {
      return [QUERY_KEYS.EITL_LABELS, QUERY_SECTIONS.INPUT_GET_EITL_LABELS, ...args];
    },
    [QUERY_SECTIONS.CONTENT_MANAGEMENT_EITL_LABELS_ADD]: ({
      name
    }: Pick<eitlLabelsApiTypes.NormalizedEitlLabel, 'name'>) => {
      return [QUERY_KEYS.EITL_LABELS, QUERY_SECTIONS.CONTENT_MANAGEMENT_EITL_LABELS_ADD, { name }];
    },
    [QUERY_SECTIONS.CONTENT_MANAGEMENT_EITL_LABELS_RENAME]: ({
      id,
      name
    }: Pick<eitlLabelsApiTypes.NormalizedEitlLabel, 'id' | 'name'>) => {
      return [
        QUERY_KEYS.EITL_LABELS,
        QUERY_SECTIONS.CONTENT_MANAGEMENT_EITL_LABELS_RENAME,
        { id, name }
      ];
    },
    [QUERY_SECTIONS.CONTENT_MANAGEMENT_EITL_LABELS_DELETE]: ({
      id
    }: Pick<eitlLabelsApiTypes.NormalizedEitlLabel, 'id'>) => {
      return [QUERY_KEYS.EITL_LABELS, QUERY_SECTIONS.CONTENT_MANAGEMENT_EITL_LABELS_DELETE, { id }];
    },
    [QUERY_SECTIONS.CONTENT_MANAGEMENT_EITL_LABELS_IMPORT]: ({ file }: { file: Blob }) => {
      return [
        QUERY_KEYS.EITL_LABELS,
        QUERY_SECTIONS.CONTENT_MANAGEMENT_EITL_LABELS_IMPORT,
        { file }
      ];
    }
  },
  [QUERY_KEYS.CAI_CATEGORIES]: {
    [QUERY_SECTIONS.CONTENT_MANAGEMENT_CAI_CATEGORIES_EDIT]: ({
      ...args
    }: Pick<
      caiCategoriesApiTypes.NormalizedCaiCategory,
      'id' | 'name' | 'description' | 'doc_meta_category_id' | 'surface_in_filter'
    >) => {
      return [
        QUERY_KEYS.CAI_CATEGORIES,
        QUERY_SECTIONS.CONTENT_MANAGEMENT_CAI_CATEGORIES_EDIT,
        { ...args }
      ];
    },
    [QUERY_SECTIONS.CONTENT_MANAGEMENT_CAI_CATEGORIES_ADD]: ({
      ...args
    }: Pick<
      caiCategoriesApiTypes.NormalizedCaiCategory,
      'name' | 'description' | 'doc_meta_category_id' | 'surface_in_filter'
    >) => {
      return [
        QUERY_KEYS.CAI_CATEGORIES,
        QUERY_SECTIONS.CONTENT_MANAGEMENT_CAI_CATEGORIES_ADD,
        { ...args }
      ];
    }
  },
  [QUERY_KEYS.SCRAPED_CATEGORIES]: {
    [QUERY_SECTIONS.CONTENT_MANAGEMENT_SCRAPED_CATEGORIES_EDIT]: ({
      id,
      doc_cai_category_id
    }: Pick<scrapedCategoriesApiTypes.NormalizedScrapedCategory, 'id' | 'doc_cai_category_id'>) => {
      return [
        QUERY_KEYS.SCRAPED_CATEGORIES,
        QUERY_SECTIONS.CONTENT_MANAGEMENT_SCRAPED_CATEGORIES_EDIT,
        { id, doc_cai_category_id }
      ];
    }
  },
  [QUERY_KEYS.REQUIREMENT_TYPES]: {
    [QUERY_SECTIONS.REQUIREMENT_TYPES_EDIT]: ({
      id
    }: Pick<requirementTypesApiTypes.NormalizedRequirementTypeFromResponse, 'id'>) => {
      return [QUERY_KEYS.REQUIREMENT_TYPES, QUERY_SECTIONS.REQUIREMENT_TYPES_EDIT, { id }];
    }
  }
};

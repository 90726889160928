import type { ConceptPageHandlers } from './useConceptPageHandlers.types';

import { useEffect } from 'react';

export const useConceptPageLifecycle = ({
  onGetConcept
}: {
  onGetConcept: ConceptPageHandlers['handleGetConcept'];
}) => {
  useEffect(onGetConcept, [onGetConcept]);
};

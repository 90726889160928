export enum CRAWL_STATUS {
  SKIPPED = 'skipped',
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
  INGESTED = 'ingested',
  UPDATED = 'updated',
  INFO = 'info',
  DEBUG = 'debug',
  FAILED = 'failed',
  RAW = 'raw'
}

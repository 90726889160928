import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Typography from './Typography';
import { CRAWL_STATUS } from '../../shared/features/crawlerSettings/reportDocuments/reportDocuments.constants';

const defaultStyles = {
  [CRAWL_STATUS.SUCCESS]: 'badge_success',
  [CRAWL_STATUS.WARNING]: 'badge_warning',
  [CRAWL_STATUS.ERROR]: 'badge_error',
  [CRAWL_STATUS.FAILED]: 'badge_error',
  [CRAWL_STATUS.INFO]: 'badge_info',
  [CRAWL_STATUS.SKIPPED]: 'badge_warning',
  [CRAWL_STATUS.UPDATED]: 'badge_success',
  [CRAWL_STATUS.INGESTED]: 'badge_success',
  [CRAWL_STATUS.DEBUG]: 'badge_info'
};

const defaultSize = {
  big: 'badge_big',
  small: 'badge_small',
  medium: 'badge_medium'
};

function Badge({ title, children, type, className, size, name, onClick }) {
  return (
    <div
      title={title}
      onClick={onClick}
      name={name}
      className={cn('badge', defaultStyles[type], defaultSize[size], className)}
    >
      <Typography variant="p" bold color="white">
        {children}
      </Typography>
    </div>
  );
}

Badge.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  name: PropTypes.string
};

Badge.defaultProps = {
  title: '',
  type: '',
  size: '',
  onClick: () => {}
};

export default Badge;

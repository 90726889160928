import { fetch, BASE_API_OPTIONS } from 'fetch';
import * as apiTypes from './requirementTypes.api.types';

export const fetchRequirementTypes: apiTypes.FetchRequirementTypes = async params => {
  return fetch({
    url: `/requirement-type`,
    dataType: 'RequirementType',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};

export const addRequirementTypes: apiTypes.AddRequirementTypes = async params => {
  return fetch({
    url: `/requirement-type`,
    dataType: 'RequirementType',
    method: 'POST',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};

export const updateRequirementTypes: apiTypes.ModifyRequirementTypes = async (id, params) => {
  return fetch({
    url: `/requirement-type/${id}`,
    dataType: 'RequirementType',
    method: 'PATCH',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};

export const updateRequirementTypesByConcept: apiTypes.ModifyRequirementTypesByConcept = async (
  id,
  params
) => {
  return fetch({
    url: `/requirement-type/concept/${id}`,
    dataType: 'RequirementType',
    method: 'PATCH',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};

import type { ConceptStatusData } from './useConceptStatusData.types';
import type { ConceptStatusProps } from '../ConceptStatus.types';
import type { ConceptStatusOptions } from './useConceptStatusOptions.types';

import * as uiLib from '@compliance.ai/web-components';

import { useMemo } from 'react';

export const useConceptStatusData = ({
  concept,
  options
}: {
  concept: ConceptStatusProps['concept'];
  options: ConceptStatusOptions['options'];
}): ConceptStatusData => {
  const formattedData: ConceptStatusData['formattedData'] = useMemo(
    () => ({
      createdAt: uiLib.formatDate(concept?.created_at, {
        format: uiLib.DATE_FORMATS.API_DATE_TIME,
        passedTimezone: uiLib.getLocalTimezone(),
        timezone: uiLib.TIMEZONES.UTC
      }),
      updatedAt: uiLib.formatDate(concept?.updated_at, {
        format: uiLib.DATE_FORMATS.API_DATE_TIME,
        passedTimezone: uiLib.getLocalTimezone(),
        timezone: uiLib.TIMEZONES.UTC
      }),
      status: concept.is_active ? options[0] : options[1]
    }),
    [concept?.created_at, concept.is_active, concept?.updated_at, options]
  );

  return {
    formattedData
  };
};

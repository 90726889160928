import type { FC } from 'react';
import type { ConceptDetailsProps } from './ConceptDetails.types';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';
import { ConceptConfirmationModal } from '../ConceptConfirmationModal';

import {
  useConceptDetailsData,
  useConceptDetailsHandlers,
  useConceptDetailsLifecycle,
  useConceptDetailsMutations,
  useConceptDetailsQueries
} from './hooks';

import './_concept-details.scss';

export const ConceptDetails: FC<ConceptDetailsProps> = props => {
  const queries = useConceptDetailsQueries({ props });
  const { localState, localActions, formattedData } = useConceptDetailsData({ props });
  const mutations = useConceptDetailsMutations({ props, localState, localActions });
  const handlers = useConceptDetailsHandlers({
    props,
    localState,
    localActions,
    queries,
    mutations
  });
  useConceptDetailsLifecycle({
    onConceptChange: handlers.handleCalculateConcept,
    onRequirementTypeChange: handlers.handleRequirementTypeChange
  });
  return (
    <div className="concept-details">
      <div>
        <div className="concept-details__header">
          <uiLib.Typography type={uiLib.TYPOGRAPHY_TYPES.H5}>General Info</uiLib.Typography>
          <div className="concept-details__header__buttons">
            {!props.isNewMode && (
              <uiLib.Button
                onClick={handlers.handleToggleConfirmationModal}
                type={uiLib.BUTTON_TYPES.DELETE}
              >
                Delete
              </uiLib.Button>
            )}
            <uiLib.Button
              onClick={handlers.handleToggleEditMode}
              type={uiLib.BUTTON_TYPES.SECONDARY}
            >
              {localState.isEditMode ? 'Cancel' : 'Edit'}
            </uiLib.Button>
            {localState.isEditMode && (
              <uiLib.Button onClick={handlers.handleSaveChanges} type={uiLib.BUTTON_TYPES.SUCCESS}>
                Save Changes
              </uiLib.Button>
            )}
          </div>
        </div>
        <div className="concept-details__body">
          <uiLib.TextField
            classNames={{ labeledFieldContainer: 'input_4' }}
            label="Name"
            value={localState.details.name}
            isDisabled={!localState.isEditMode}
            onChange={value => handlers.handleChanges('name', value)}
            errorMessage={localState.errorFields.name}
            isError={Boolean(localState.errorFields.name)}
          />
          <uiLib.TextField
            classNames={{ labeledFieldContainer: 'input_2' }}
            label="Id"
            value={localState.details.id}
            isDisabled
            onChange={value => handlers.handleChanges('id', value)}
            errorMessage={localState.errorFields.id}
            isError={Boolean(localState.errorFields.id)}
          />
          <uiLib.LabeledField label="Associated Meta-Concept" className="input_4">
            <uiLib.Select
              type={uiLib.SELECT_TYPES.DEFAULT}
              isSearchable
              isAsync
              isDisabled={!localState.isEditMode}
              loadOptions={handlers.handleMetaConceptFetch}
              shouldFetchDefaultOptions
              value={formattedData.selectedMetaCategory}
              onChange={handlers.handleChangeMetaConcept}
            />
            {localState.errorFields.metaConcept && (
              <uiLib.Typography status={uiLib.TYPOGRAPHY_STATUS.ERROR}>
                {localState.errorFields.metaConcept}
              </uiLib.Typography>
            )}
          </uiLib.LabeledField>
          <uiLib.TextField
            classNames={{ labeledFieldContainer: 'input_4' }}
            label="Description"
            value={localState.details.description ?? ''}
            isDisabled={!localState.isEditMode}
            onChange={value => handlers.handleChanges('description', value)}
            errorMessage={localState.errorFields.description}
            isError={Boolean(localState.errorFields.description)}
          />
          <uiLib.TextField
            classNames={{ labeledFieldContainer: 'input_8' }}
            label="Advanced Search"
            value={localState.details.advancedSearch ?? ''}
            isDisabled={!localState.isEditMode}
            onChange={value => handlers.handleChanges('advancedSearch', value)}
            errorMessage={localState.errorFields.advancedSearch}
            isError={Boolean(localState.errorFields.advancedSearch)}
          />
          <uiLib.Checkbox
            label="Is Requirement Type"
            className="input_1"
            isChecked={localState.details.isRequirementType}
            isDisabled={!localState.isEditMode}
            onChange={value => handlers.handleChanges('isRequirementType', value)}
          />
        </div>
      </div>
      <ConceptConfirmationModal
        isOpen={localState.isConfirmationModalOpen}
        onConfirm={handlers.handleOnDelete}
        onClose={handlers.handleToggleConfirmationModal}
      />
      {queries.fetchRequirementType.isLoading && <uiLib.Loader />}
      {localState.details.isRequirementType && (
        <elements.RequirementType
          isEditMode={localState.isEditMode && !mutations.updateRequirementType.isLoading}
          docIdsSearch={localState.docIdsSearch}
          documents={localState.documents}
          isActive={localState.isActive}
          errorFields={localState.errorFields}
          isLoading={mutations.getDocuments.isLoading}
          onAddDocuments={handlers.handleSearchDocs}
          onChangeSearchDocs={localActions.setDocIdsSearch}
          onChangeStatus={handlers.handleChangeStatus}
          onDeleteDoc={handlers.handleDeleteDocument}
          onSortDocuments={handlers.handleSortDocuments}
        />
      )}
    </div>
  );
};

import type { AnyAction } from 'redux';
import type { ConceptReduxActions } from './useConceptsReduxActions.types';

import * as conceptsActions from 'shared/features/concepts/concepts.actions';

import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import type { CreateConceptResponse } from '../concepts.apiV2.types';

export const useConceptsReduxActions = (): ConceptReduxActions => {
  const dispatch = useDispatch();

  const fetchConcepts: ConceptReduxActions['fetchConcepts'] = useCallback(
    async (...args: Parameters<typeof conceptsActions.getConcepts>) => {
      await dispatch(conceptsActions.getConcepts(...args) as unknown as AnyAction);
    },
    [dispatch]
  );

  const createConcept: ConceptReduxActions['createConcept'] = useCallback(
    async (...args: Parameters<typeof conceptsActions.createConcepts>) => {
      return (await dispatch(
        conceptsActions.createConcepts(...args) as unknown as AnyAction
      )) as unknown as CreateConceptResponse;
    },
    [dispatch]
  );

  const modifyConcept: ConceptReduxActions['modifyConcept'] = useCallback(
    async (...args: Parameters<typeof conceptsActions.updateConcepts>) => {
      await dispatch(conceptsActions.updateConcepts(...args) as unknown as AnyAction);
    },
    [dispatch]
  );

  const deleteConcept: ConceptReduxActions['deleteConcept'] = useCallback(
    async (...args: Parameters<typeof conceptsActions.removeConcepts>) => {
      await dispatch(conceptsActions.removeConcepts(...args) as unknown as AnyAction);
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      fetchConcepts,
      createConcept,
      modifyConcept,
      deleteConcept
    }),
    [createConcept, deleteConcept, fetchConcepts, modifyConcept]
  );
};

import type { ConceptDetailsHandlers } from './useConceptDetailsHandlers.types';

import { useEffect } from 'react';

export const useConceptDetailsLifecycle = ({
  onConceptChange,
  onRequirementTypeChange
}: {
  onConceptChange: ConceptDetailsHandlers['handleCalculateConcept'];
  onRequirementTypeChange: ConceptDetailsHandlers['handleRequirementTypeChange'];
}) => {
  useEffect(onConceptChange, [onConceptChange]);
  useEffect(onRequirementTypeChange, [onRequirementTypeChange]);
};

import type { FC } from 'react';
import type { ConceptConfirmationModalProps } from './ConceptConfirmationModal.types';

import * as uiLib from '@compliance.ai/web-components';

export const ConceptConfirmationModal: FC<ConceptConfirmationModalProps> = ({
  isOpen,
  onConfirm,
  onClose
}) => {
  return (
    <uiLib.Modal
      isOpen={isOpen}
      onSubmit={onConfirm}
      onClose={onClose}
      title="Delete concept"
      withFooter
      primaryButtonText="Delete Concept"
    >
      <div>
        <uiLib.Typography>Are you sure you want to delete this concept?</uiLib.Typography>
      </div>
    </uiLib.Modal>
  );
};

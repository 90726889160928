import type * as apiTypes from './requirementTypes.api.types';

export const normalizeRequirementTypesResponse = (
  response: apiTypes.RequirementTypesResponse
): apiTypes.NormalizedRequirementTypesResponse => {
  return {
    count: response.count,
    requirementTypes: response.items.map(normalizeRequirementType)
  };
};

export const normalizeRequirementType = (
  requirementType: apiTypes.RequirementTypeFromResponse
): apiTypes.NormalizedRequirementTypeFromResponse => {
  return {
    id: requirementType.id,
    conceptId: requirementType.concept_id,
    jurisdictionId: requirementType.jurisdiction_id,
    jurisdictionName: requirementType.jurisdiction_name,
    docIds: requirementType.doc_ids,
    stringency: requirementType.stringency,
    sharedResourceId: requirementType.shared_resource_id,
    isShared: requirementType.is_shared,
    isActive: requirementType.is_active,
    details: requirementType.details
  };
};

import type { FC } from 'react';
import type { StatusCellProps, StatusCellValue } from './StatusCell.types';

import * as uiLib from '@compliance.ai/web-components';

export const StatusCell: FC<StatusCellProps> = ({ cellValue }) => {
  const statusCell = cellValue as StatusCellValue;
  return (
    <uiLib.Typography
      status={statusCell.isError ? uiLib.TYPOGRAPHY_STATUS.ERROR : uiLib.TYPOGRAPHY_STATUS.INITIAL}
    >
      {statusCell.pipeline_status}
    </uiLib.Typography>
  );
};

import type { RequirementTypeData } from './useRequirementTypeData.types';
import type { RequirementTypeProps } from '../RequirementType.types';

import { RequirementTypeStatusOptions } from './useRequirementTypeStatusOptions.types';

import { useMemo } from 'react';

export const useRequirementTypeData = ({
  props,
  options
}: {
  props: RequirementTypeProps;
  options: RequirementTypeStatusOptions['options'];
}): RequirementTypeData => {
  const formattedData: RequirementTypeData['formattedData'] = useMemo(
    () => ({
      status: props.isActive ? options[0] : options[1],
      jurisdictionOptions: props.documents.map(doc => ({
        value: doc.jurisdiction_id,
        label: doc.jurisdiction
      }))
    }),
    [options, props.documents, props.isActive]
  );

  return {
    formattedData
  };
};

import type * as uiLib from '@compliance.ai/web-components';
import type { ConceptPageData } from './useConceptPageData.types';

import * as elements from '../elements';

import { useMemo } from 'react';
import type { ConceptPageHandlers } from './useConceptPageHandlers.types';

export const useConceptPageTabs = ({
  id,
  isNewMode,
  concept,
  onChangeConcept
}: {
  id: ConceptPageData['formattedData']['id'];
  isNewMode: ConceptPageData['formattedData']['isNewMode'];
  concept: ConceptPageData['reduxState']['concept'];
  onChangeConcept: ConceptPageHandlers['handleUpdateConcept'];
}): {
  tabs: uiLib.Tab[];
} => {
  return useMemo(
    () => ({
      tabs: [
        {
          content: (
            <elements.ConceptDetails
              id={id}
              concept={concept}
              isNewMode={isNewMode}
              onChangeConcept={onChangeConcept}
            />
          ),
          title: 'Concept Details'
        }
      ]
    }),
    [concept, id, isNewMode, onChangeConcept]
  );
};

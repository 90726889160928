import type { ConceptPageData } from './useConceptPageData.types';
import type { GlobalState } from 'shared/reducers';

import { getCurrentUser } from 'shared/features/user/user.selectors';
import { getFirstConcept } from 'shared/features/concepts/concepts.selectors';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useMemo, useState } from 'react';
import * as constants from '../elements/ConceptDetails/ConceptDetails.constants';

export const useConceptPageData = (): ConceptPageData => {
  const { id } = useParams();
  const [activeTabIndex, setActiveTabIndex] =
    useState<ConceptPageData['localState']['activeTabIndex']>(0);

  const reduxState = useSelector<GlobalState, ConceptPageData['reduxState']>(state => ({
    currentUser: getCurrentUser(state),
    concept: getFirstConcept(state)
  }));

  const localState: ConceptPageData['localState'] = {
    activeTabIndex
  };

  const localActions: ConceptPageData['localActions'] = useMemo(
    () => ({
      setActiveTabIndex
    }),
    []
  );

  const formattedData: ConceptPageData['formattedData'] = useMemo(
    () => ({
      id: id ?? '',
      isNewMode: id === constants.NEW_CONCEPT_ID
    }),
    [id]
  );

  return {
    localState,
    localActions,
    formattedData,
    reduxState
  };
};

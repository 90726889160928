import type { FC } from 'react';
import type { ConceptStatusProps } from './ConceptStatus.types';

import * as uiLib from '@compliance.ai/web-components';
import classNames from 'classnames';

import { useConceptStatusData, useConceptStatusOptions, useConceptStatusHandlers } from './hooks';

import './_concept-status.scss';

export const ConceptStatus: FC<ConceptStatusProps> = ({ concept, isDisables, onChangeConcept }) => {
  const { options } = useConceptStatusOptions();
  const { formattedData } = useConceptStatusData({ concept, options });
  const handlers = useConceptStatusHandlers({ onChangeConcept });
  return (
    <div className="concept-status">
      <div className="concept-status__dates">
        <uiLib.Typography isItalic={true}>{`Created: ${formattedData.createdAt}`}</uiLib.Typography>
        <uiLib.Typography isItalic={true}>{`Updated: ${formattedData.updatedAt}`}</uiLib.Typography>
      </div>
      <uiLib.LabeledField label="Status">
        <uiLib.Select
          className={classNames('concept-status__status', {
            'concept-status__status__active': concept.is_active,
            'concept-status__status__deactivated': !concept.is_active
          })}
          type={uiLib.SELECT_TYPES.DEFAULT}
          isDisabled={isDisables}
          value={formattedData.status}
          options={options}
          isClearable={false}
          isSearchable={false}
          onChange={handlers.handleChangeStatus}
        />
      </uiLib.LabeledField>
      <uiLib.Button type={uiLib.BUTTON_TYPES.SECONDARY} onClick={handlers.handleCloseDetails}>
        <uiLib.IconClose />
      </uiLib.Button>
    </div>
  );
};

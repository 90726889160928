import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { fetchConcepts } from 'shared/features/jurisdictionAnalyzerConcepts/jurisdictionAnalyzerConcept.actions';
import { getConcepts } from 'shared/features/jurisdictionAnalyzerConcepts/jurisdictionAnalyzerConcept.selector';
import { clearErrors } from 'shared/features/error/actions';
import ConceptsModal from './JurisdictionAnalyzerConceptsModal';

import PopupInfo from '../../components/PopupInfo';
import { popupName } from '../../constants/popupInfoConst';
import Spinner from '../../components/spinner';

class JurisdictionAnalyzerConcepts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      concept: {}
    };
  }

  componentDidMount() {
    this.props.fetchConcepts();
    this.props.clearErrors('jurisdiction_analyzer_concepts');
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  openModal = concept => {
    this.setState({ showModal: true, concept });
  };

  closeModal = () => {
    this.setState({ showModal: false, concept: {} });
  };

  render() {
    const { concepts } = this.props;
    const header = (
      <div className="adminToolTableFlexRow flexHeader">
        <div>Name of Concept</div>
        <div>Description</div>
        <div>Concept Active</div>
      </div>
    );

    const list_items_flex = concepts?.jurisdiction_analyzer_concepts?.map(concept => (
      <div
        key={concept.id}
        className="adminToolTableFlexRow"
        onClick={() => this.openModal(concept)}
      >
        <div>{concept.name}</div>
        <div>{concept.description}</div>
        <div>{concept.active.toString()}</div>
      </div>
    ));

    return (
      <div className="AdminToolPageTemplate thinBtn">
        <h1>Jurisdiction Analyzer: Concepts</h1>
        <Button bsStyle="primary" onClick={() => this.openModal()}>
          Create
        </Button>
        <div style={{ clear: 'both' }} />
        <div className="adminToolTableFlex">
          {header}
          {list_items_flex}
        </div>
        <ConceptsModal
          closeModal={this.closeModal}
          showModal={this.state.showModal}
          concept={this.state.concept || {}}
        />
        {!this.props.concepts && (
          <div className="spinnerWrapper">
            <Spinner />
          </div>
        )}
        <PopupInfo
          show={!!this.props.errors.jurisdiction_analyzer_concepts}
          onHide={() => {
            this.props.clearErrors('jurisdiction_analyzer_concepts');
          }}
          popupName={popupName.errorRespPopup}
          errorText={this.props.errors?.jurisdiction_analyzer_concepts?.[0]?.responseText}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    concepts: getConcepts(state),
    errors: state.errors
  };
};

const ReduxConcepts = connect(mapStateToProps, {
  fetchConcepts,
  clearErrors
})(JurisdictionAnalyzerConcepts);

export default ReduxConcepts;

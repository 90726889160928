import type { ConceptStatusHandlers } from './useConceptStatusHandlers.types';
import type { ConceptStatusProps } from '../ConceptStatus.types';

import ROUTES from 'admin/constants/routes';
import {
  CONTENT_MANAGEMENT_TAB_KEYS,
  CONTENT_MANAGEMENT_TAB_QUERY_PARAM
} from 'admin/containers/ContentManagement/ContentManagement.constants';

import { useLocation } from 'react-router-dom';
import { useHistory } from 'admin/utils/hooks';
import * as uiLib from '@compliance.ai/web-components';

export const useConceptStatusHandlers = ({
  onChangeConcept
}: {
  onChangeConcept: ConceptStatusProps['onChangeConcept'];
}): ConceptStatusHandlers => {
  const history = useHistory();
  const location = useLocation();

  const handleCloseDetails: ConceptStatusHandlers['handleCloseDetails'] = () => {
    history.push(
      `${ROUTES.contentManagement}?${CONTENT_MANAGEMENT_TAB_QUERY_PARAM}=${CONTENT_MANAGEMENT_TAB_KEYS.CONCEPTS}`,
      location.state
    );
  };

  const handleChangeStatus: ConceptStatusHandlers['handleChangeStatus'] = value => {
    onChangeConcept({ isActive: (value as uiLib.SelectOption).value });
  };

  return {
    handleCloseDetails,
    handleChangeStatus
  };
};

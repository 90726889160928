import type { ConceptPageData } from './useConceptPageData.types';
import type { ConceptPageHandlers } from './useConceptPageHandlers.types';

import * as helpers from './useConceptPageHandlers.helpers';
import * as uiLib from '@compliance.ai/web-components';

import { useConceptsReduxActions } from 'shared/features/concepts/hooks';
import { useCallback } from 'react';
import { useHistory } from 'admin/utils/hooks';

export const useConceptPageHandlers = ({
  localActions,
  formattedData,
  reduxState
}: {
  localActions: ConceptPageData['localActions'];
  formattedData: ConceptPageData['formattedData'];
  reduxState: ConceptPageData['reduxState'];
}): ConceptPageHandlers => {
  const history = useHistory();
  const conceptsReduxActions = useConceptsReduxActions();

  const handleGetConcept: ConceptPageHandlers['handleGetConcept'] = useCallback(() => {
    if (uiLib.isNumber(Number(formattedData.id))) {
      conceptsReduxActions.fetchConcepts({ id: Number(formattedData.id) });
    }
  }, [conceptsReduxActions, formattedData.id]);

  const handleUpdateConcept: ConceptPageHandlers['handleUpdateConcept'] = useCallback(
    async concept => {
      const updatedFields = helpers.getUpdatedFields(concept, reduxState.concept);
      const updatedConcept = {
        user: `${reduxState.currentUser?.first_name} ${reduxState.currentUser?.last_name}`,
        ...updatedFields
      };
      if (formattedData.isNewMode) {
        const concept = await conceptsReduxActions.createConcept({
          ...updatedConcept,
          id: 0
        });
        return concept.id;
      } else {
        await conceptsReduxActions.modifyConcept({
          ...updatedConcept,
          id: Number(formattedData.id)
        });
        handleGetConcept();
      }
      return formattedData.id;
    },
    [
      conceptsReduxActions,
      formattedData.id,
      formattedData.isNewMode,
      handleGetConcept,
      reduxState.concept,
      reduxState.currentUser?.first_name,
      reduxState.currentUser?.last_name
    ]
  );

  const handleChangeTab: ConceptPageHandlers['handleChangeTab'] = tabIndex => {
    localActions.setActiveTabIndex(tabIndex);
  };

  return {
    handleGetConcept,
    handleUpdateConcept,
    handleChangeTab
  };
};
